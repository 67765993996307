<template>
	<div class="page" :class="isRotated ? 'page-top' : 'page-let'">
		<div v-if="!isRotated" class="video-loop-left">
			<video
				class="video-loop-mp4"
				src="../../assets/video/cover_left.mp4"
				muted
				autoplay
				loop
			></video>
		</div>
		<div v-if="isRotated" class="video-loop-top">
			<video
				class="video-loop-mp4"
				src="../../assets/video/cover_top.mp4"
				muted
				autoplay
				loop
			></video>
		</div>
		<div class="log" :class="isRotated ? 'log-top' : 'log-let'">
			<div class="container">
				<div id="login_container"></div>
				<div class="log-on-test">飞书扫码，快速登录</div>
				<div class="log-on-test-assistant">一天内保存登录</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, reactive, watch } from "vue";
import { feishuAppID } from "../../utils/common";

const loginContainerId = "login_container";
const goto = ref("");
const redirectUri = ref("");
const QRLoginObj = ref(null);

const screenWidth = ref(window.innerWidth);
const screenHeight = ref(window.innerHeight);
const isRotated = ref(window.innerWidth < window.innerHeight);

let resizeTimer: NodeJS.Timeout | null = null;
let orientationTimer: NodeJS.Timeout | null = null;

const handleResize = () => {
	if (resizeTimer) {
		clearTimeout(resizeTimer);
	}
	resizeTimer = setTimeout(() => {
		screenWidth.value = window.innerWidth;
		screenHeight.value = window.innerHeight;
		isRotated.value = window.innerWidth < window.innerHeight;
	}, 200);
};

const handleOrientationChange = () => {
	if (orientationTimer) {
		clearTimeout(orientationTimer);
	}
	orientationTimer = setTimeout(() => {
		if (window.matchMedia("(orientation: portrait)").matches) {
			location.reload();
		}
	}, 200);
};

redirectUri.value = `${window?.location?.origin}/feishu/login`;
const REDIRECT_URI = encodeURIComponent(redirectUri.value);
const larkAppID = feishuAppID();

goto.value = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${larkAppID}&redirect_uri=${REDIRECT_URI}&response_type=code&state=STATE`;

const handleMessage = (event) => {
	const loginTmpCode = event.data;
	const origin = event.origin;

	if (QRLoginObj.value?.matchOrigin(origin)) {
		if (window.location.search.indexOf("code") !== -1) return;

		window.location.replace(`${goto.value}&tmp_code=${loginTmpCode.tmp_code}`);
	}
};
onMounted(() => {
	QRLoginObj.value = (window as any).QRLogin({
		id: loginContainerId,
		goto: goto.value,
		width: "260",
		height: "260",
		style: "border:0;",
	});
	window.addEventListener("message", handleMessage, false);
	window.addEventListener("resize", handleResize, false);
	window.addEventListener("orientationchange", handleOrientationChange, false);
});

onBeforeUnmount(() => {
	window.removeEventListener("message", handleMessage, false);
	window.removeEventListener("resize", handleResize, false);
	window.removeEventListener(
		"orientationchange",
		handleOrientationChange,
		false
	);
});
</script>
<style scoped lang="scss">
.page-let {
	flex-direction: row;
}
.page-top {
	flex-direction: column;
}

.log-let {
	width: calc(100vw - 651 / 1024 * 100vh);
	.container {
		min-height: 100vh;
		width: 100%;
	}
}
.log-top {
	height: calc(100vh - 521 / 820 * 100vw);
	.container {
		min-width: 100vw;
		height: 100%;
	}
}
.page {
	display: flex;
	width: 100vw;
	height: 100vh;
	.video-loop-left {
		height: 100vh;
		width: calc(651 / 1024 * 100vh);
		.video-loop-mp4 {
			height: 100%;
		}
	}
	.video-loop-top {
		width: 100vw;
		height: calc(521 / 820 * 100vw);
		.video-loop-mp4 {
			width: 100%;
		}
	}
	.log {
		height: 100%;

		.container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.log-on-test {
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
			}

			.log-on-test-assistant {
				padding-top: 5px;
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				color: #686d82;
			}
		}
	}
}
</style>
