import { getTenant } from '@nio-fe/pcp-sdk-core/vue';
import { wxWorkIdMap } from './common';
import { getEnv } from './application';

export function wxWorkLogin() {
  const urlParams = new URLSearchParams(window.location.search);
  let tenant_id = getTenant();
  if (!tenant_id) {
    if (urlParams.get('to')?.includes('alps-phb')) {
      tenant_id = 'alps-phb';
    } else {
      tenant_id = 'phb';
    }
  }
  // oauth2之后需要重定向回来的链接，用于调用BFF层的登陆接口
  const redirect = `${
    window.location.origin
  }/wxwork/login?tenant_id=${tenant_id}&redirect_to=${encodeURIComponent(
    window.location.origin + (urlParams.get('to') ?? ''),
  )}`;
  const redirectUri = encodeURIComponent(redirect!);
  const { agentId, corpId } =
    wxWorkIdMap[getEnv() as 'stg' | 'prod'][tenant_id as 'phb' | 'alps-phb'];
  const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corpId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&agentid=${agentId}#wechat_redirect`;

  location.href = authUrl;
}
