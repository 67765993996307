//获取不同环境的phbSSO登录地址
let sso_appID = '101618';
export function ssoUrl() {
  let url = window.location.href;
  let ssourl: string;
  if (url.indexOf('dev-eu') > -1) {
    ssourl = 'https://signin-intl-dev.nio.com/';
    sso_appID = '1000399';
  } else if (url.indexOf('test-eu') > -1) {
    ssourl = 'https://signin-intl-test.nio.com/';
    sso_appID = '1000399';
  } else if (url.indexOf('stg-eu') > -1) {
    ssourl = 'https://signin-stg.eu.nio.com/';
    sso_appID = '1000399';
  } else if (url.indexOf('phb-eu') > -1) {
    ssourl = 'https://signin.eu.nio.com/';
    sso_appID = '1000399';
  } else if (url.indexOf('dev') > -1) {
    ssourl = 'https://signin-dev.nio.com/';
  } else if (url.indexOf('test') > -1) {
    ssourl = 'https://signin-test.nio.com/';
  } else if (url.indexOf('stg') > -1) {
    ssourl = 'https://signin-stg.nio.com/';
  } else if (url.indexOf('127.0.0.1') > -1 || url.indexOf('localhost') > -1) {
    ssourl = 'https://signin-dev.nio.com/';
  } else {
    ssourl = 'https://signin.nio.com/';
  }
  return ssourl;
}

export function ssoLogin(redirect?: string) {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectUri = !redirect
    ? `${window.location.origin}/account/sso?redirect_to=${encodeURIComponent(
        window.location.origin + (urlParams.get('to') ?? ''),
      )}`
    : redirect;
  const REDIRECT_URI = encodeURIComponent(redirectUri);
  const sso_end_point = ssoUrl();
  location.href = `${sso_end_point}/oauth2/authorize?client_id=${sso_appID}&redirect_uri=${REDIRECT_URI}&response_type=code`;
}
