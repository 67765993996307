// 先判断是否为ipad，不是的话直接跳转SSO
import { createApp } from 'vue';
import { isTablet, deviceHelper } from './utils/util';
import qrLogin from './pages/pwaLog/index.vue';
import { ssoLogin } from './utils/openSSO';
import { handleHashUrl } from './utils/hashUrl';
import { wxWorkLogin } from './utils/wxWorkLogin';
// import { registerSW } from 'virtual:pwa-register';

// registerSW({ immediate: true });

handleHashUrl();

if (deviceHelper().isWxWork) {
  wxWorkLogin();
} else if (!isTablet()) {
  ssoLogin();
} else {
  const app = createApp(qrLogin);
  const reg = /test|dev|stg|local/gi;
  // if (reg.test(window.location.href)) app.use(new VConsole());
  app.mount('#app');
}

// createApp ，生成二维码
